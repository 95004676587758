<template>
  <div class="terms-conditions">
    <div class="wrapper">
      <h3 class="title">Terms and Conditions</h3>
      <h5>Artikel 1 - Definities In deze voorwaarden wordt verstaan onder:</h5>
      <p>
        Ondernemer: Bloemenmaker, onderdeel van ASD Online B.V.<br />
        Consument/afnemer: de&nbsp;natuurlijke persoon of&nbsp;rechtspersoon die
        een overeenkomst op&nbsp;afstand aangaat met de&nbsp;ondernemer;<br />
        Overeenkomst op&nbsp;afstand: een overeenkomst waarbij in&nbsp;het kader
        van een door de&nbsp;ondernemer georganiseerd systeem voor verkoop
        op&nbsp;afstand van producten en/of diensten, tot en&nbsp;met het
        sluiten van de&nbsp;overeenkomst uitsluitend gebruik gemaakt wordt van
        &#233;&#233;n&nbsp;of&nbsp;meer technieken voor communicatie
        op&nbsp;afstand;<br />
        Techniek voor communicatie op&nbsp;afstand: middel dat kan worden
        gebruikt voor het sluiten van een overeenkomst, zonder dat
        consument/afnemer en&nbsp;ondernemer gelijktijdig in&nbsp;dezelfde
        ruimte zijn samengekomen;<br />
        Dag: kalenderdag;<br />
        Duurzame gegevensdrager: elk middel dat de&nbsp;consument/afnemer
        of&nbsp;ondernemer in&nbsp;staat stelt om&nbsp;informatie die aan hem
        persoonlijk is&nbsp;gericht, op&nbsp;te&nbsp;slaan op&nbsp;een manier
        die toekomstige raadpleging en&nbsp;ongewijzigde reproductie van
        de&nbsp;opgeslagen informatie mogelijk maakt.
      </p>
      <h5>Artikel 2 - Identiteit van de ondernemer</h5>
      <p>
        Bloemenmaker, onderdeel van ASD Online B.V.<br />
        Vestigingsadres (geen bezoekadres):<br />
        Nieuwe Hescheweg 290<br />
        5342 NM&nbsp;OSS
      </p>
      <h5>Bereikbaarheid:</h5>
      <p>
        Maandag tot en&nbsp;met vrijdag: 09:00 uur&nbsp;&mdash; 17: 00 uur<br />
        Zaterdag: 09:00 uur&nbsp;&mdash; 13:00<br />
        Gesloten op&nbsp;zon- en&nbsp;feestdagen.<br />
        <nobr>E-mailadres</nobr>:
        <a href="mailto:info@bloemenmaker.nl">info@bloemenmaker.nl</a><br />
        Telefoonnummer: 085&ndash;0861012<br />
        <nobr>KvK-nummer</nobr>: 78033276<br />
        <nobr>BTW-identificatienummer</nobr>: NL861239805B01
      </p>
      <h5>Artikel 3 - Toepasselijkheid</h5>
      <p>
        Deze algemene voorwaarden zijn van toepassing op&nbsp;elk aanbod van
        de&nbsp;ondernemer en&nbsp;op&nbsp;elke tot stand gekomen overeenkomst
        op&nbsp;afstand tussen ondernemer en&nbsp;consument/afnemer. Voordat
        de&nbsp;overeenkomst op&nbsp;afstand wordt gesloten, wordt de&nbsp;tekst
        van deze algemene voorwaarden aan de&nbsp;consument/afnemer
        op&nbsp;elektronische wijze beschikbaar gesteld.
      </p>
      <h5>Artikel 4 - Het aanbod</h5>
      <p>
        Indien een aanbod een beperkte geldigheidsduur heeft of&nbsp;onder
        voorwaarden geschiedt, wordt dit nadrukkelijk in&nbsp;het aanbod
        vermeld.<br />
        Het aanbod geeft een zo&nbsp;volledige en&nbsp;nauwkeurige omschrijving
        van de&nbsp;aangeboden producten en/of diensten als mogelijk is.
        Op&nbsp;de&nbsp;website van de&nbsp;Bloemenmaker verkopen wij boeketten,
        planten en&nbsp;aanverwante artikelen als vazen, kaartjes en&nbsp;andere
        cadeauartikelen. Gebruikte afbeeldingen zijn een waarheidsgetrouwe
        weergave van de&nbsp;aangeboden producten en/of diensten, doch
        in&nbsp;verband met seizoensinvloeden en&nbsp;de&nbsp;beschikbaarheid
        van producten kan het voorkomen dat er&nbsp;gelijkwaardige producten ter
        vervanging van getoonde producten worden verwerkt. Kennelijke
        vergissingen of&nbsp;fouten in&nbsp;het aanbod binden de&nbsp;ondernemer
        niet.<br />
        Elk aanbod bevat zodanige informatie, dat voor de&nbsp;consument/afnemer
        duidelijk is&nbsp;wat de&nbsp;rechten en&nbsp;verplichtingen zijn, die
        aan de&nbsp;aanvaarding van het aanbod zijn verbonden. Dit betreft
        in&nbsp;het bijzonder: de&nbsp;prijs inclusief belastingen,
        de&nbsp;eventuele kosten van aflevering, de&nbsp;wijze waarop
        de&nbsp;overeenkomst tot stand zal komen en&nbsp;welke handelingen
        daarvoor nodig zijn, het niet van toepassing zijn van het
        herroepingrecht, de&nbsp;wijze van betaling, aflevering
        of&nbsp;uitvoering van de&nbsp;overeenkomst.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsConditions",
};
</script>
